import React from "react";
import UI from "../../../../@components/UI";
import useService from "../../../useService";
import { ethers } from "ethers";
import erc721Artifact from "../../../../abis/contracts/modules/event/Event.sol/Event.json";
import erc20Artifact from "../../../../abis/contracts/modules/token/ERC20Token.sol/ERC20Token.json";
import collectionMultiArtifact from "../../../../abis/contracts/modules/collectionNativeMultipleImages/CollectionNativeMultipleImages.sol/CollectionNativeMultipleImages.json";
import { useSnackbar } from "notistack";
import useHandleErrors from "../../../../@components/hooks/useHandleErrors";
import customHooks from "../../../../@components/hooks";
import { useFormData } from "../../context";
import CustomHooks from "../../../../@components/hooks";
import { useSmartAccountClient } from "@alchemy/aa-alchemy/react";
import { encodeFunctionData } from "viem";
import PaymentDialog from "./PaymentDialog";

const Button = (props) => {
  const { applyCustomStyles, title, DAO, collection, collectionId, onSuccess } =
    props;
  const { data: formData } = useFormData();
  let query = CustomHooks.useQuery();
  const { enqueueSnackbar } = useSnackbar();
  const { handleMetamaskError, handleApiError } = useHandleErrors();
  const [isLoading, setIsLoading] = React.useState(false);
  const [showPaymentDialog, setShowPaymentDialog] = React.useState(false);
  const { prepareCollectionNFTUri } = UI.useIPFS();
  const collectionService = useService();
  const selectedNFT = query.get("selectedNFT");

  const { client: smartAccountClient } = useSmartAccountClient({
    type: "MultiOwnerModularAccount",
    gasManagerConfig: {
      policyId:
        window.env.CHAIN === "AMOY"
          ? "2a7bc98c-5422-4d90-a8b1-91de6fdadc0d"
          : "9e29758c-dcb6-43a1-85f5-2acfd5a0c060",
    },
    opts: {
      txMaxRetries: 20,
    },
  });

  const { refetch: fetchRandomImageData } = customHooks.useFetch(
    ["collections"],
    () => collectionService.getNextCollectionImage(DAO?.id, collectionId),
    {
      enabled: false,
    }
  );

  const saveBuyer = async (ticketId, ticket_image_url) => {
    await collectionService
      .buyTicket(collectionId, {
        dao_id: DAO?.id,
        ticket_id: ticketId,
        ...(ticket_image_url ? { ticket_image_url } : {}),
        additional_info: {
          ...formData?.additional_info,
        },
      })
      .then(() => {
        enqueueSnackbar("The NFT was successfully purchased", {
          variant: "success",
        });
        setIsLoading(false);
        onSuccess();
      })
      .catch((error) => {
        enqueueSnackbar(error?.message, { variant: "error" });
      });
  };

  const getTicketIdFromReceipt = async (uoReceipt) => {
    const erc721ABI = erc721Artifact.abi.find(
      (item) => item.type === "event" && item.name === "TicketMint"
    );

    const erc721Interface = new ethers.Interface([erc721ABI]);

    const ticketMintEventFragment = erc721Interface.getEvent("TicketMint");
    const TicketMintSignature = ticketMintEventFragment.topicHash;

    const logs = uoReceipt.logs;

    const ticketMintLogs = logs.filter(
      (log) => log.topics[0] === TicketMintSignature
    );

    const decodedEvents = ticketMintLogs.map((log) => {
      return erc721Interface.parseLog(log);
    });

    return Number(decodedEvents[0].args[1]);
  };

  const buyTicket = async () => {
    if (
      collection.has_multiple_images &&
      collection.manual_nft_selection === false
    ) {
      // if the collection has multiple images
      await buyMultiTicket();
    } else if (
      collection.has_multiple_images &&
      collection.manual_nft_selection === true
    ) {
      // if the collection has multiple images, and user manually selected NFT image
      await buyMultiTicketManualSelected();
    } else {
      // otherwise, we call buyStandardTicket
      await buyStandardTicket();
    }
  };

  const buyMultiTicketManualSelected = async () => {
    setIsLoading(true);

    try {
      const priceToBuy = ethers.parseUnits(
        String(collection?.total_price_with_tax),
        collection?.payment_token?.decimals
      );

      //find the nft in the collection under collection_images based on selectedNFT
      const nftData = collection?.collection_images.find(
        (nft) => parseInt(nft.id) === parseInt(selectedNFT)
      );

      const nftUri = await prepareCollectionNFTUri(collection, nftData, DAO);
      let uo;

      if (collection?.payment_token?.type === "NATIVE") {
        uo = [
          {
            target: collection.nft_address,
            data: encodeFunctionData({
              abi: collectionMultiArtifact.abi,
              functionName: "buyTicket",
              args: [nftUri],
            }),
            value: priceToBuy,
          },
        ];
      } else {
        //ERC20
        uo = [
          {
            target: collection?.payment_token?.address,
            data: encodeFunctionData({
              abi: erc20Artifact.abi,
              functionName: "approve",
              args: [collection.nft_address, priceToBuy],
            }),
          },
          {
            target: collection.nft_address,
            data: encodeFunctionData({
              abi: collectionMultiArtifact.abi,
              functionName: "buyTicket",
              args: [nftUri],
            }),
          },
        ];
      }

      const userOperation = await smartAccountClient.sendUserOperation({
        uo,
      });

      await smartAccountClient.waitForUserOperationTransaction(userOperation);
      const uoReceipt = await smartAccountClient.getUserOperationReceipt(
        userOperation.hash
      );

      const ticketId = await getTicketIdFromReceipt(uoReceipt);

      await saveBuyer(ticketId, nftData.image_url);
    } catch (error) {
      handleMetamaskError(error);
      setIsLoading(false);
    }
  };

  const buyMultiTicket = async () => {
    setIsLoading(true);

    try {
      const priceToBuy = ethers.parseUnits(
        String(collection?.total_price_with_tax),
        collection?.payment_token?.decimals
      );

      await fetchRandomImageData()
        .then(async (response) => {
          const randomImageData = response.data;

          const nftUri = await prepareCollectionNFTUri(
            collection,
            randomImageData,
            DAO
          );
          try {
            let uo;
            if (collection?.payment_token?.type === "NATIVE") {
              uo = [
                {
                  target: collection.nft_address,
                  data: encodeFunctionData({
                    abi: collectionMultiArtifact.abi,
                    functionName: "buyTicket",
                    args: [nftUri],
                  }),
                  value: priceToBuy,
                },
              ];
            } else {
              //ERC20
              uo = [
                {
                  target: collection?.payment_token?.address,
                  data: encodeFunctionData({
                    abi: erc20Artifact.abi,
                    functionName: "approve",
                    args: [collection.nft_address, priceToBuy],
                  }),
                },
                {
                  target: collection.nft_address,
                  data: encodeFunctionData({
                    abi: collectionMultiArtifact.abi,
                    functionName: "buyTicket",
                    args: [nftUri],
                  }),
                },
              ];
            }
            const userOperation = await smartAccountClient.sendUserOperation({
              uo,
            });

            await smartAccountClient.waitForUserOperationTransaction(
              userOperation
            );
            const uoReceipt = await smartAccountClient.getUserOperationReceipt(
              userOperation.hash
            );

            const ticketId = await getTicketIdFromReceipt(uoReceipt);

            await saveBuyer(ticketId, randomImageData.image_url);
          } catch (error) {
            handleMetamaskError(error);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          handleApiError(error);
          setIsLoading(false);
        });
    } catch (error) {
      handleMetamaskError(error);
      setIsLoading(false);
    }
  };

  const buyStandardTicket = async () => {
    setIsLoading(true);
    try {
      const priceToBuy = ethers.parseUnits(
        String(collection?.total_price_with_tax),
        collection?.payment_token?.decimals
      );

      let uo;
      if (collection?.payment_token?.type === "NATIVE") {
        uo = [
          {
            target: collection.nft_address,
            data: encodeFunctionData({
              abi: erc721Artifact.abi,
              functionName: "buyTicket",
              args: [],
            }),
            value: priceToBuy,
          },
        ];
      } else {
        // ERC20
        uo = [
          {
            target: collection?.payment_token?.address,
            data: encodeFunctionData({
              abi: erc20Artifact.abi,
              functionName: "approve",
              args: [collection.nft_address, priceToBuy],
            }),
          },
          {
            target: collection.nft_address,
            data: encodeFunctionData({
              abi: erc721Artifact.abi,
              functionName: "buyTicket",
              args: [],
            }),
          },
        ];
      }

      const userOperation = await smartAccountClient.sendUserOperation({
        uo,
      });

      await smartAccountClient.waitForUserOperationTransaction(userOperation);
      const uoReceipt = await smartAccountClient.getUserOperationReceipt(
        userOperation.hash
      );

      const ticketId = await getTicketIdFromReceipt(uoReceipt);
      await saveBuyer(ticketId);
    } catch (error) {
      handleMetamaskError(error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <UI.Busy.FullscreenIndicator show={isLoading} />

      <UI.Button
        sx={{
          mt: "10px",
          color: applyCustomStyles ? "#000" : "#fff",
          backgroundColor: applyCustomStyles
            ? `${DAO?.dao_style[0]?.data.background_color}`
            : "#000",
          "&:hover": {
            backgroundColor: applyCustomStyles
              ? `${DAO?.dao_style[0]?.data.background_color}`
              : "#944DFF",
            border: applyCustomStyles
              ? `0px solid ${DAO?.dao_style[0]?.data.background_color}`
              : "0px solid #944DFF",
          },
        }}
        disabled={isLoading}
        type={"primary"}
        title={title}
        onClick={() => setShowPaymentDialog(true)}
      />
      <PaymentDialog
        alog
        currency={collection?.currency}
        paymentToken={collection?.payment_token}
        isBusy={isLoading}
        price={collection?.total_price_with_tax}
        open={showPaymentDialog}
        onConfirm={() => {
          setShowPaymentDialog(false);
          buyTicket();
        }}
        onClose={() => setShowPaymentDialog(false)}
      />
    </>
  );
};

export default Button;
