import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import UI from "../../../../@components/UI";
import { Paper } from "@mui/material";
import { useFormData } from "../../context";
import truncateEthAddress from "truncate-eth-address";
import { useBalance } from "wagmi";
import { ethers } from "ethers";
import CloseIcon from "@mui/icons-material/Close";
import { Alert } from "@mui/lab";
import { useTranslation } from "react-i18next";

const TokenItem = (props) => {
  const { name, currency, amount } = props;
  return (
    <Box {...props} sx={{ width: "100%", ...props.sx }}>
      <Paper
        sx={{
          boxShadow: "none",
          cursor: "default",
          borderRadius: "10px",
          backgroundColor: "rgba(255, 255, 255, 0.12)",
          transition: "all 0.2s ease-in-out",
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.3)",
          },
        }}
      >
        <Box
          sx={{
            height: "60px",
            padding: { md: "0px 30px", mobile: "0px 15px" },
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                color: "#000",
                display: { md: "flex", mobile: "none" },
                fontSize: "14px !important",
              }}
              variant={"subtitle2"}
            >
              {name}
            </Typography>
            <Typography
              sx={{
                color: "#000",
                display: { md: "none", mobile: "flex" },
              }}
              variant={"h8"}
            >
              {name}
            </Typography>
          </Box>

          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                width: "25px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <UI.PaymentIcon currency={currency} />
            </Box>
            <Box
              sx={{
                ml: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography variant={"h7"} sx={{ color: "#000" }}>
                {parseFloat(amount).toFixed(2)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

const PaymentDialog = ({
  isBusy,
  open,
  onClose,
  price,
  onConfirm,
  currency,
  paymentToken,
}) => {
  const { t } = useTranslation();
  const { data: formData } = useFormData();
  const [hasEnoughBalance, setHasEnoughBalance] = React.useState(false);

  const { data: nativeBalance } = useBalance({
    address: formData?.wallet_address,
  });

  const { data: erc20Balance } = useBalance({
    address: formData?.wallet_address,
    token: paymentToken?.address,
  });

  useEffect(() => {
    if (!nativeBalance || !erc20Balance) return;

    const nativeAmount = ethers.formatEther(nativeBalance?.value || 0);
    const erc20Amount = ethers.formatUnits(
      erc20Balance?.value || 0,
      paymentToken?.decimals
    );
    const usersBalance =
      paymentToken?.type === "NATIVE" ? nativeAmount : erc20Amount;

    if (parseFloat(price) > parseFloat(usersBalance)) {
      setHasEnoughBalance(false);
    } else {
      setHasEnoughBalance(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nativeBalance, erc20Balance]);

  const getBalanceBasedOnCurrency = (currency) => {
    if (currency === "MATIC") {
      return ethers.formatEther(nativeBalance?.value || 0);
    } else {
      return ethers.formatUnits(
        erc20Balance?.value || 0,
        paymentToken?.decimals
      );
    }
  };

  return (
    <Dialog
      fullWidth
      PaperProps={{
        sx: {
          position: "relative",
          borderRadius: "20px",
          width: "480px",
          p: { md: "30px", mobile: "30px 20px" },
          margin: { md: "32px", mobile: "10px" },
          backgroundColor: "rgba(255, 255, 255, 0.7) !important",
          backdropFilter: "blur(20px)",
        },
      }}
      onClose={onClose}
      open={open}
    >
      <CloseIcon
        onClick={onClose}
        sx={{
          color: "#000",
          position: "absolute",
          right: { md: "30px", mobile: "15px" },
          top: { md: "30px", mobile: "33px" },
          cursor: "pointer",
          "&:hover": {
            transition: "all 0.1s ease 0s",
            transform: "scale(1.1) rotate(-90deg)",
          },
        }}
      />
      <Box>
        <Typography
          variant={"h4"}
          sx={{
            textAlign: "center",
            mb: "20px",
            color: "#000",
          }}
        >
          {t("paymentDialog.title")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            width: "100%",
            mb: "10px",
          }}
        >
          <Typography
            variant={"h5"}
            sx={{
              ml: "6px",
              flexDirection: "column",
              justifyContent: "center",
              color: "#000",
            }}
          >
            {t("paymentDialog.wallet")}
          </Typography>
          <Typography
            variant={"h5"}
            sx={{
              ml: "10px",
              flexDirection: "column",
              justifyContent: "center",
              mb: "3px",
              color: "#000",
            }}
          >
            <UI.CopyToClipboard
              label={truncateEthAddress(formData?.wallet_address || "")}
              value={formData?.wallet_address || ""}
            />
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <TokenItem
            sx={{ mb: "10px" }}
            name={t("paymentDialog.balance")}
            currency={currency}
            amount={getBalanceBasedOnCurrency(currency)}
          />
          <TokenItem
            sx={{ mb: "10px" }}
            name={t("paymentDialog.price")}
            currency={currency}
            amount={price}
          />
        </Box>
        {!hasEnoughBalance && (
          <Alert
            variant="outlined"
            severity="info"
            sx={{
              color: "#4DB8FF",
              mt: { mobile: "8px", md: "8px" },
              mb: { mobile: "8px", md: "0px" },
              borderRadius: "5px",
            }}
          >
            <Typography
              variant={"subtitle2"}
              sx={{
                fontSize: "12px !important",
                color: "#4DB8FF",
                fontFamily: "Open Sans",
                fontWeight: "bold",
                ml: "0px",
                mt: "-1px",
              }}
            >
              {t("paymentDialog.alert")}
            </Typography>
          </Alert>
        )}
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center", mt: "20px" }}>
        <Box sx={{ display: "flex", flexDirection: "column", width: "250px" }}>
          <UI.SmartAccountActionButton
            title={t("paymentDialog.button")}
            isLoading={isBusy}
            onClick={() => {
              onConfirm();
            }}
          />
        </Box>
      </Box>
    </Dialog>
  );
};

export default PaymentDialog;
